<template>
  <div class="pa-4 assets-grid fill-height">
    <h1>
      <v-row align="center">
        <v-col>
          {{ $t("menuAssets") }}
        </v-col>
        <v-col align="end">
          <template v-if="!currentUser.isOperator">
            <v-btn
              v-on:click="importAssetsDialog = true"
              color="primary"
              outlined
            >
              {{ $t("assetCsvImport") }}
            </v-btn>
            <v-btn
              v-on:click="newAssetDialog = true"
              class="ms-6"
              color="primary"
            >
              {{ $t("btnNewAsset") }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </h1>

    <div class="tab-container">
      <v-tabs v-model="tab" class="outlined-tabs mb-2">
        <v-tab>{{ $t("assetsPaired") }}</v-tab>
        <v-tab>{{ $t("assetsNotPaired") }}</v-tab>
      </v-tabs>

      <div style="height: 100%">
        <!-- PAIRED -->
        <div v-if="tab === 0" class="et-container">
          <EntitiesTable
            v-bind:headers="headers"
            v-bind:sorting-fields="sortingFields"
            v-bind:items="pairedAssets"
            v-bind:defaultSort="sortingFields[0].value"
            v-bind:loading="loading"
            v-bind:actionsColumn="!currentUser.isOperator"
            v-bind:actions="{
              edit: !currentUser.isOperator,
              enableDisable: false,
              delete: !currentUser.isOperator,
            }"
            v-bind:bulkColumn="!currentUser.isOperator"
            v-on:table-click="handleClick"
            v-on:table-grouping-header="handleGroupClick"
            v-on:table-bulk-customer="manageAssetBulk($event, 0)"
            v-on:table-bulk-group="manageAssetBulk($event, 1)"
            v-on:table-bulk-user="manageAssetBulk($event, 2)"
            v-on:table-bulk-mailing="manageAssetBulk($event, 3)"
            v-on:table-bulk-delete="manageAssetBulk($event, 4)"
            v-on:table-edit="manageAsset($event, 0)"
            v-on:table-delete="manageAsset($event, 1)"
            v-on:table-bulk-enable="/* Do nothing */"
            v-on:table-bulk-disable="/* Do nothing */"
            v-on:table-disable="/* Do nothing */"
            v-on:table-enable="/* Do nothing */"
            group-by="groupName"
          />
        </div>

        <!-- NOT PAIRED -->
        <div v-if="tab === 1" class="et-container">
          <EntitiesTable
            v-bind:headers="headers"
            v-bind:sorting-fields="sortingFields"
            v-bind:items="notPairedAssets"
            v-bind:defaultSort="sortingFields[0].value"
            v-bind:loading="loading"
            v-bind:actionsColumn="!currentUser.isOperator"
            v-bind:actions="{
              edit: !currentUser.isOperator,
              enableDisable: false,
              delete: !currentUser.isOperator,
            }"
            v-bind:bulkColumn="!currentUser.isOperator"
            v-on:table-click="handleClick"
            v-on:table-grouping-header="handleGroupClick"
            v-on:table-bulk-customer="manageAssetBulk($event, 0)"
            v-on:table-bulk-group="manageAssetBulk($event, 1)"
            v-on:table-bulk-user="manageAssetBulk($event, 2)"
            v-on:table-bulk-mailing="manageAssetBulk($event, 3)"
            v-on:table-bulk-delete="manageAssetBulk($event, 4)"
            v-on:table-edit="manageAsset($event, 0)"
            v-on:table-delete="manageAsset($event, 1)"
            v-on:table-bulk-enable="/* Do nothing */"
            v-on:table-bulk-disable="/* Do nothing */"
            v-on:table-disable="/* Do nothing */"
            v-on:table-enable="/* Do nothing */"
            group-by="groupName"
          />
        </div>
      </div>
    </div>

    <v-dialog
      v-model="importAssetsDialog"
      v-if="importAssetsDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("assetCsvImport") }}</v-card-title>
        <v-card-text>
          <div
            v-html="$t('csvFormatInfo')"
            class="mb-6"
            style="max-width: 60ch"
          />

          <div class="d-flex flex-row align-center mb-4">
            <v-text-field
              v-model="csvSeparator"
              v-bind:label="$t('csvSeparator')"
              v-bind:title="$t('csvSeparator')"
              v-bind:outlined="outlinedPref"
              v-bind:dense="outlinedPref"
              style="max-width: 15ch"
              clearable
              hide-details
            />
            <span class="mx-4" />
            <v-file-input
              v-bind:label="$t('csvFile')"
              v-bind:outlined="outlinedPref"
              v-bind:dense="outlinedPref"
              v-on:change="parseCsv"
              accept="text/csv"
              clearable
              hide-details
            />
          </div>

          <div>
            <v-alert
              v-if="importError || importDetails"
              v-bind:color="importError ? 'error' : 'indigo'"
              border="left"
              style="max-width: 60ch"
              text
              outlined
            >
              {{ importError || importDetails }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetCsv" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="csvSaveDisabled"
            v-on:click="importAssetsFromCsv"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newAssetDialog"
      v-if="newAssetDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>
          {{
            !editing ? $t("btnNewAsset") : $t("btnEdit") + " " + asset.serial
          }}
        </v-card-title>
        <v-card-text class="mt-2">
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-if="!editing"
              v-model="asset.serial"
              v-bind:label="$t('assetSerial')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-select
              v-else
              v-model="asset.companyId"
              v-bind:items="customersList"
              v-bind:label="$t('assetOwner')"
              v-bind:outlined="outlinedPref"
              v-on:change="asset.groupId = undefined"
              class="mx-2"
              item-text="name"
              item-value="id"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="asset.boxMacAddress"
              v-bind:label="$t('assetMac')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="asset.plate"
              v-bind:label="$t('assetPlate')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-select
              v-if="asset.companyId == currentUser.companyId"
              v-model="asset.groupId"
              v-bind:items="groupsList"
              v-bind:label="$t('assetGroup')"
              v-bind:outlined="outlinedPref"
              item-text="name"
              item-value="id"
              class="mx-2"
              clearable
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-on:click="manageAsset(asset, 1)" color="error" text>
            {{ $t("btnDelete") }}
          </v-btn>
          <v-spacer />
          <v-btn v-on:click="resetAsset" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validAsset"
            v-on:click="postAsset"
            color="primary"
            text
          >
            {{ !editing ? $t("btnCreate") : $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bulkUpdateDialog"
      v-if="bulkUpdateDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ mode === 0 ? $t("btnAssignCustomer") : $t("btnAssignGroup") }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedId"
            v-bind:items="mode === 0 ? customersList : groupsList"
            v-bind:label="mode === 0 ? $t('assetOwner') : $t('assetGroup')"
            v-bind:outlined="outlinedPref"
            item-text="name"
            item-value="id"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetBulkUpdate" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn v-on:click="updateAssetsBulk" color="primary" text>
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bulkUsersDialog"
      v-if="
        bulkUsersDialog && (currentUser.isSuperAdmin || currentUser.isAdmin)
      "
      width="75ch"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("btnAssignUsers") }}
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="updatedUsers"
            v-bind:label="$t('menuUsers')"
            v-bind:items="usersListFiltered || []"
            v-bind:return-object="true"
            v-bind:outlined="outlinedPref"
            item-value="id"
            item-text="nameSurname"
            item-disabled="bulkDisabled"
            hide-details
            multiple
            clearable
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="userFilter"
                    v-bind:label="$t('searchPlaceholder')"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetBulkUpdate" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!updatedUsers || updatedUsers.length === 0"
            v-on:click="updateAssetsBulk"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bulkMailingDialog"
      v-if="bulkMailingDialog"
      width="75ch"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("btnAssignMailingLists") }}
        </v-card-title>
        <v-card-subtitle v-html="$t('comboBoxNoDataGeneric')"></v-card-subtitle>

        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-combobox
                  v-model="customEmails['mailingList_Trace']"
                  v-bind:label="$t('mailingList_Trace')"
                  v-bind:items="emailSuggestions || []"
                  v-bind:return-object="false"
                  v-bind:outlined="outlinedPref"
                  append-icon=""
                  hide-details
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-combobox
                  v-model="customEmails['mailingList_Low']"
                  v-bind:label="$t('mailingList_Low')"
                  v-bind:items="emailSuggestions || []"
                  v-bind:return-object="false"
                  v-bind:outlined="outlinedPref"
                  append-icon=""
                  hide-details
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-combobox
                  v-model="customEmails['mailingList_Medium']"
                  v-bind:label="$t('mailingList_Medium')"
                  v-bind:items="emailSuggestions || []"
                  v-bind:return-object="false"
                  v-bind:outlined="outlinedPref"
                  append-icon=""
                  hide-details
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-combobox
                  v-model="customEmails['mailingList_High']"
                  v-bind:label="$t('mailingList_High')"
                  v-bind:items="emailSuggestions || []"
                  v-bind:return-object="false"
                  v-bind:outlined="outlinedPref"
                  append-icon=""
                  hide-details
                  multiple
                  clearable
                  chips
                  deletable-chips
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetBulkUpdate" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="
              customEmails.mailingList_Trace.length === 0 &&
              customEmails.mailingList_Low.length === 0 &&
              customEmails.mailingList_Medium.length === 0 &&
              customEmails.mailingList_High.length === 0
            "
            v-on:click="updateAssetsBulk"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmAssetDelete')"
      v-bind:title="$t('dialogAssetDeleteConfirm')"
      v-on:cancel="confirmDelete = false"
    />

    <v-dialog v-model="updating" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.outlined-tabs {
  border-bottom: 1px solid #dfe7f0;
  flex-grow: 0;
}

.assets-grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.tab-container {
  max-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.et-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import AssetInfo from "../models/AssetInfo";
import ConfirmationDialog from "../components/ConfirmationDialog";
import EntitiesTable from "../components/EntitiesTable";
import AssetDataRequestBody from "../models/AssetDataRequestBody";

export default {
  name: "Assets",

  components: { ConfirmationDialog, EntitiesTable },

  data: () => ({
    tab: 0,
    selected: [],
    mode: -1,
    bulkUpdateDialog: false,
    bulkMailingDialog: false,
    bulkUsersDialog: false,
    selectedId: undefined,
    confirmDelete: false,
    confirmCallback: () => {},
    newAssetDialog: false,
    importAssetsDialog: false,
    asset: new AssetInfo(),
    loading: false,
    updating: false,
    editing: false,
    csvSeparator: ",",
    importError: undefined,
    importDetails: undefined,
    csvRows: [],
    highlightedMeasures: [],
    userFilter: undefined,
    updatedUsers: [],
    customEmails: {
      mailingList_Trace: [],
      mailingList_Low: [],
      mailingList_Medium: [],
      mailingList_High: [],
    },
  }),

  computed: {
    csvSaveDisabled() {
      return (
        !this.csvSeparator ||
        !!this.importError ||
        !this.importDetails ||
        this.csvRows.length === 0
      );
    },
    validAsset() {
      return !!this.asset.serial;
    },
    pairedAssets() {
      return this.assetsList.filter((v) => !!v.boxMacAddress);
    },
    notPairedAssets() {
      return this.assetsList.filter((v) => !v.boxMacAddress);
    },
    usersListFiltered() {
      return (this.usersList || []).filter((u) =>
        this.userFilter ? u.matchSearchTerm(this.userFilter) : true
      );
    },
    sortingFields() {
      const fields = [
        { text: this.$t("assetSerial"), value: "serial" },
        { text: this.$t("assetPlate"), value: "plate" },
        { text: this.$t("assetBrand"), value: "brand" },
        { text: this.$t("assetModel"), value: "model" },
        { text: this.$t("assetGroup"), value: "groupName" },
        { text: this.$t("assetType"), value: "type" },
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.splice(4, 0, {
          text: this.$t("assetOwner"),
          value: "companyName",
        });
      }

      if (this.hideAssetSerialPref) {
        fields.splice(0, 1);
      }

      return fields;
    },
    headers() {
      const fields = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetStatus"), align: "start", sortable: false, value: "connection", width: "9%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetSerial"), align: "start", sortable: false, value: "serial", width: "14%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetPlate"), align: "start", sortable: false, value: "plate", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: `${this.$t("assetBrand")} - ${this.$t("assetModel")}`, align: "start", sortable: false, value: "brand" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetType"), align: "center", sortable: false, value: "type", width: "9%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetAlarms"), align: "center", sortable: false, value: "alarmsCount" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "measuresHighlights" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "actions", width: "5%" },
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.splice(5, 0, {
          text: `${this.$t("assetOwner")} - ${this.$t("assetGroup")}`,
          align: "center",
          sortable: false,
          value: "companyName",
          width: "9%",
        });
      } else {
        fields.splice(5, 0, {
          text: this.$t("assetGroup"),
          align: "center",
          sortable: false,
          value: "groupName",
          width: "9%",
        });
      }

      if (this.hideAssetSerialPref) {
        fields.splice(1, 1);
      }

      return fields;
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.fetchHighlights();
    this.$store.commit("setCurrentPage", Pages.ASSETS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuAssets"),
      to: "/assets",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
    pairedAssets: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length > 0 && newValue.length > oldValue.length) {
          this.fetchHighlights();
        }
      },
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          [...this.signalRBaseRegexes, ...this.highlightedMeasures],
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    fetchHighlights() {
      Promise.allSettled(
        this.pairedAssets.map((asset) => {
          const highlights = asset.userInterface.measures?.highlights || [];
          const requestBody = new AssetDataRequestBody();

          requestBody.ids = [asset.id];
          requestBody.paths = highlights.map((t) => {
            const newHighlighted = `measures/@[^@$/]+/${t}`;

            if (!this.highlightedMeasures.includes(newHighlighted)) {
              this.highlightedMeasures.push(newHighlighted);
            }

            return `measures/${t}`;
          });
          requestBody.includeMetadata = true;

          return requestBody.paths.length > 0
            ? ApiRequests.getSavedMeasures(
                requestBody,
                undefined,
                undefined,
                undefined
              )
            : Promise.resolve();
        })
      )
        .then((results) => {
          results.forEach((result) => {
            if (result.status === "fulfilled" && result.value != undefined) {
              const configData = JSON.parse(result.value.config.data);
              const requestedData = result.value.data[configData.ids[0]];

              Object.keys(requestedData).forEach((k) => {
                this.$store.dispatch("saveMessage", requestedData[k]);
              });
            }
          });

          this.updateFiltersRules(this.clientId);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
        });
    },
    postAsset() {
      if (
        this.currentUser.companyId == this.asset.companyId &&
        this.asset.groupId != undefined &&
        this.asset.groupId != ""
      ) {
        // eslint-disable-next-line prettier/prettier
        const selectedGroup = this.groupsList.find((g) => g.id === this.asset.groupId);

        this.updateGroup(selectedGroup, [this.asset.type, this.asset.subType]);
      }

      if (!this.editing) {
        ApiRequests.createAsset(
          this.asset.getAssetCreateBody(),
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("assetCreateSuccess")
            );
            this.resetAsset();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("assetCreateFailure"),
            });
          }
        );
      } else {
        if (this.currentUser.companyId != this.asset.companyId) {
          this.asset.groupId = undefined;
        }

        const updateBody = this.asset.getAssetUpdateBody();

        ApiRequests.updateAsset(
          this.asset.id,
          updateBody,
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("assetUpdateSuccess")
            );
            this.resetAsset();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("assetUpdateFailure"),
            });
          }
        );
      }
    },
    callback(total, res, text1, text2, text3) {
      const sent = res.reduce(
        (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
        0
      );

      if (total > sent) {
        this.$bus.$emit(Messages.ERROR_MSG, {
          error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
          description: text2,
        });
      } else {
        this.$bus.$emit(Messages.SUCCESS_MSG, text1);
      }

      this.$bus.$emit(Messages.RESET_TABLE_SELECTED);
      this.fetchAllData();
      this.updating = false;
    },
    manageAssetBulk(selected, mode) {
      switch (mode) {
        case 0:
          this.selected = selected;
          this.mode = mode;
          this.bulkUpdateDialog = true;
          break;
        case 1:
          this.selected = selected;
          this.mode = mode;
          this.bulkUpdateDialog = true;
          break;
        case 2:
          this.selected = selected;
          this.mode = mode;
          this.bulkUsersDialog = true;
          break;
        case 3:
          this.selected = selected;
          this.mode = mode;
          this.bulkMailingDialog = true;
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () => {
            this.updating = true;
            Promise.allSettled(
              selected.map((v) => {
                return ApiRequests.deleteAsset(v.id, undefined, undefined);
              })
            ).then((res) => {
              this.confirmCallback = () => {};
              this.callback(
                selected.length,
                res,
                this.$t("assetDeleteSuccess"),
                this.$t("assetDeleteFailure"),
                "Error: %s% of %t% assets deleted"
              );
            });
          };
          break;
      }
    },
    manageAsset(asset, mode) {
      switch (mode) {
        case 0:
          this.editing = true;
          Object.assign(this.asset, asset);
          this.newAssetDialog = true;
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () =>
            ApiRequests.deleteAsset(
              asset.id,
              () => {
                this.resetAsset();
                this.confirmCallback = () => {};
                this.$bus.$emit(
                  Messages.SUCCESS_MSG,
                  this.$t("assetDeleteSuccess")
                );
                this.fetchAllData();
              },
              (err) => {
                if (process.env.NODE_ENV === "development") console.error(err);
                this.confirmCallback = () => {};
                this.$bus.$emit(Messages.ERROR_MSG, {
                  error: err?.response?.data?.error || err,
                  description: this.$t("assetDeleteFailure"),
                });
              }
            );
          break;
      }
    },
    updateGroup(group, tags) {
      if (group == undefined || group == "") return;

      // Create group tags
      tags.forEach((t) => {
        if (!group.functions.includes(t)) group.functions.push(t);
      });

      // Cleaning up
      group.functions = group.functions.filter((tag) => !!tag);

      ApiRequests.updateGroup(
        group.id,
        group.getCreateUpdateGroupBody(),
        () => {},
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
        }
      );
    },
    updateAssetsBulk() {
      this.updating = true;

      const update = () => {
        Promise.allSettled(
          this.selected.map((v) => {
            return ApiRequests.updateAsset(
              v.id,
              v.getAssetUpdateBody(),
              undefined,
              undefined
            );
          })
        ).then((res) => {
          this.resetBulkUpdate();
          this.callback(
            this.selected.length,
            res,
            this.$t("assetUpdateSuccess"),
            this.$t("assetUpdateFailure"),
            "Error: %s% of %t% assets updated"
          );
        });
      };

      switch (this.mode) {
        case 0:
          this.selected.forEach((v) => {
            v.groupId = undefined;
            v.companyId = this.selectedId;
          });
          update();
          break;
        case 1: {
          // eslint-disable-next-line prettier/prettier
          const selectedGroup = this.groupsList.find((g) => g.id === this.selectedId);

          this.updateGroup(
            selectedGroup,
            this.selected.reduce((a, c) => [...a, c.type, c.subType], [])
          );

          this.selected.forEach((v) => (v.groupId = this.selectedId));
          update();
          break;
        }
        case 2: {
          const assetIds = this.selected.map((asset) => asset.id);

          this.updatedUsers.forEach((userToUpdate) => {
            const currentIds = [...userToUpdate.assetIds];
            currentIds.push(...assetIds);
            userToUpdate.assetIds = currentIds;
          });

          // Custom for users not for selected assets
          Promise.allSettled(
            this.updatedUsers.map((userToUpdate) => {
              return ApiRequests.updateUserAdmin(
                userToUpdate.id,
                userToUpdate.getUserUpdateBody(),
                undefined,
                undefined
              );
            })
          ).then((res) => {
            this.resetBulkUpdate();
            this.callback(
              this.updatedUsers.length,
              res,
              this.$t("usersUpdateSuccess"),
              this.$t("usersUpdateFailure"),
              "Error: %s% of %t% users updated"
            );
          });
          break;
        }
        case 3: {
          // Change the required customs
          const stringified = {
            mailingList_Trace:
              this.customEmails.mailingList_Trace.length > 0
                ? this.customEmails.mailingList_Trace.join(",")
                : undefined,
            mailingList_Low:
              this.customEmails.mailingList_Low.length > 0
                ? this.customEmails.mailingList_Low.join(",")
                : undefined,
            mailingList_Medium:
              this.customEmails.mailingList_Medium.length > 0
                ? this.customEmails.mailingList_Medium.join(",")
                : undefined,
            mailingList_High:
              this.customEmails.mailingList_High.length > 0
                ? this.customEmails.mailingList_High.join(",")
                : undefined,
          };

          Object.keys(stringified).forEach((key) => {
            if (stringified[key] != undefined) {
              this.selected.forEach((v) => (v.custom[key] = stringified[key]));
            }
          });

          update();
          break;
        }
        default:
          this.resetBulkUpdate();
          this.updating = false;
          break;
      }
    },
    resetAsset() {
      this.newAssetDialog = false;
      this.updating = false;
      this.editing = false;
      this.asset = new AssetInfo();
    },
    resetBulkUpdate() {
      this.bulkUpdateDialog = false;
      this.bulkMailingDialog = false;
      this.bulkUsersDialog = false;
      this.selected = [];
      this.mode = -1;
      this.selectedId = undefined;
      this.updatedUsers = [];
      this.customEmails = {
        mailingList_Trace: [],
        mailingList_Low: [],
        mailingList_Medium: [],
        mailingList_High: [],
      };
    },
    handleClick(value) {
      this.$router.push({ path: `/asset-details/${value.id}` });
    },
    resetCsv() {
      this.importAssetsDialog = false;
      this.csvSeparator = ",";
      this.importError = undefined;
      this.importDetails = undefined;
      this.csvRows = [];
    },
    parseCsv(file) {
      this.importError = undefined;
      this.importDetails = undefined;
      this.csvRows = [];

      if (file != undefined) {
        let fileReader = new FileReader();

        new Promise((resolve, reject) => {
          fileReader.readAsText(file, "UTF-8");
          fileReader.onload = (evt) => resolve(evt.target.result);
          fileReader.onerror = (evt) => reject(evt);
        })
          .then((res) => {
            const rows = res
              .split("\n")
              .map((r) => r.split(this.csvSeparator))
              .filter((r) => r.length === 3);

            if (rows.length === 0) {
              // eslint-disable-next-line prettier/prettier
              this.importError = this.$t("csvMissingFields").replace(": %s", "");
              return;
            }

            const header = rows.splice(0, 1)[0];
            const fields = {
              serial: header.indexOf("serial"),
              mac: header.indexOf("mac"),
              plate: header.indexOf("plate"),
            };

            if (fields.serial >= 0 && fields.mac >= 0 && fields.plate >= 0) {
              // Valid content
              // eslint-disable-next-line prettier/prettier
              this.importDetails = `${file.name}: ${rows.length} ${this.$t("assets")}`;

              rows.map((r) => {
                r[fields.serial] = ["serial", r[fields.serial]];
                r[fields.mac] = ["boxMacAddress", r[fields.mac]];
                r[fields.plate] = ["plate", r[fields.plate]];

                return r;
              });

              this.csvRows = [...rows];
            } else {
              // Some field is missing
              // eslint-disable-next-line prettier/prettier
              this.importError = this.$t("csvMissingFields").replace(
                "%s",
                Object.keys(fields)
                  .reduce((a, c) => {
                    if (fields[c] === -1) a.push(c);
                    return a;
                  }, [])
                  .join(", ")
              );
            }
          })
          .catch((err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            // eslint-disable-next-line prettier/prettier
            this.importError = this.$t("fileReadingError").replace("%s", file.name);
          });
      }
    },
    importAssetsFromCsv() {
      this.csvRows.forEach((r) => {
        this.editing = false;
        this.asset.groupId = undefined;

        r.forEach((c) => {
          this.asset[c[0]] = c[1];
        });

        this.postAsset();
      });

      this.resetCsv();
    },
    handleGroupClick(group) {
      const groupId = this.groupsList.find((g) => g.name === group)?.id;

      if (groupId == undefined) return;

      this.$router.push({ path: `/groups-details/${groupId}` });
    },
  },
};
</script>
