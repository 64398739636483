<template>
  <div class="py-4 ps-4 pe-0 fill-height grid-container">
    <v-card class="asset-details">
      <v-card-title class="d-flex flex-column align-start">
        <h2 v-if="!!asset.plate">
          {{ asset.plate }}
        </h2>
        <h3 v-else>
          {{ asset.boxMacAddress || $t("assetNoPlate") }}
        </h3>
        <h3
          v-if="asset.brand != undefined || asset.model != undefined"
          class="primary--text text--lighten-2"
        >
          {{ asset.brand }}
          {{ asset.model }}
        </h3>
        <!-- Unchangeable info -->
        <h6 class="mt-2 d-flex justify-space-between" style="width: 100%">
          <span class="primary--text text--lighten-2 me-4">
            {{ $t("assetSerial") }}
          </span>
          {{ asset.serial }}
        </h6>

        <h6
          v-if="!!asset.boxMacAddress"
          class="d-flex justify-space-between"
          style="width: 100%"
        >
          <span class="primary--text text--lighten-2 me-4">
            {{ $t("assetMac") }}
          </span>
          {{ asset.boxMacAddress }}
        </h6>

        <h6
          v-if="currentUser.isSuperAdmin && !!asset.companyId"
          class="d-flex justify-space-between"
          style="width: 100%"
        >
          <span class="primary--text text--lighten-2 me-4">
            {{ $t("assetOwner") }}
          </span>
          <div
            v-on:click="
              $router.push({
                path: `/customers-details/${asset.companyId}`,
              })
            "
            class="customer-link"
          >
            {{ asset.companyName }}
          </div>
        </h6>

        <h6
          v-if="!!asset.groupName"
          class="d-flex justify-space-between"
          style="width: 100%"
        >
          <span class="primary--text text--lighten-2 me-4">
            {{ $t("assetGroup") }}
          </span>
          <div
            v-on:click="
              $router.push({
                path: `/groups-details/${asset.groupId}`,
              })
            "
            class="customer-link"
          >
            {{ asset.groupName }}
          </div>
        </h6>
      </v-card-title>

      <v-card-text class="centered-container" flat>
        <img
          v-if="asset.image"
          v-bind:src="asset.image"
          height="auto"
          width="145ch"
        />
        <img v-else src="../assets/asset.png" height="90vh" width="auto" />
      </v-card-text>

      <v-card-text style="overflow: auto">
        <div
          v-if="asset.isForecast"
          class="primary--text text--lighten-2 font-weight-bold d-flex justify-space-between align-center mb-4"
          style="border-bottom: 1px solid #42424242"
        >
          {{ $t("forecastOnlyAsset") }}
          <v-btn
            v-if="!pureForecasts"
            v-on:click="
              $bus.$emit('present-error', {
                error: 'Work in progress',
                description: 'This function is not implemented yet',
              })
            "
            color="primary"
            text
            small
          >
            <!-- TODO: actually show the predictions -->
            {{ $t("btnShowForecasts") }}
          </v-btn>
        </div>
        <div class="custom-properties-grid pe-6">
          <div
            v-for="field in asset.listableCustoms()"
            v-bind:key="assetDbId + '-' + field"
            class="d-flex flex-row align-center justify-space-between"
          >
            <span class="body-2 primary--text text--lighten-2 me-4">
              {{ $t(asset.trimInfluxPrefix(field)) }}
            </span>
            <span class="body-2 black--text font-weight-medium">
              {{ asset.custom[field] }}
            </span>
          </div>
        </div>
      </v-card-text>

      <v-card-text style="text-align: end">
        <v-btn
          v-if="!!asset.boxMacAddress && !pureForecasts"
          v-on:click="
            $router.replace({
              path: `/alarms`,
              query: { serial: asset.serial },
            })
          "
          class="mb-2"
          color="primary"
          block
          outlined
        >
          {{ $t("menuAlarms") }}
        </v-btn>

        <v-btn v-on:click="openEditDialog" color="primary" block outlined>
          {{ $t("btnEdit") }}
        </v-btn>

        <v-btn
          v-if="
            !!asset.boxMacAddress && assetCommands.length > 0 && !pureForecasts
          "
          v-on:click="displayCommands = true"
          class="mt-2"
          color="primary"
          block
          outlined
        >
          {{ $t("btnCommands") }}
        </v-btn>
      </v-card-text>
    </v-card>

    <template v-if="!pureForecasts">
      <div class="alarms-plot">
        <v-card-title class="pa-0 grow-false">
          {{ $t("alarmsTitle") }}
        </v-card-title>
        <v-divider class="grow-false" />
        <v-card-text
          class="grow-true pa-2"
          style="display: flex; justify-content: center; align-items: center"
        >
          <HeatMap
            v-bind:data="eventsStats"
            v-bind:loading="loadingStats"
            v-bind:time-slots="timeSlots"
            v-bind:color="color"
          />
        </v-card-text>
      </div>

      <div class="dashboard-container d-flex flex-column me-2">
        <div class="d-flex align-center justify-space-between">
          <v-card-title class="pa-0">
            {{ $t("menuDynamicDetails") }}
          </v-card-title>

          <v-btn
            v-if="asset.customComponent.enabled"
            v-on:click="
              $router.push({ path: `/asset-dynamic-details/${assetDbId}` })
            "
            color="primary"
            outlined
            x-small
          >
            {{ $t("openDynamicDetails") }}
          </v-btn>
        </div>

        <div
          v-if="asset.customComponent.enabled"
          style="position: relative; height: 100%"
        >
          <DynamicDashboard
            v-bind:asset-id="assetId"
            v-bind:asset="asset"
            class="dashboard outlined pa-4"
            style="border-radius: 4px; overflow: hidden"
            ref="dashboard"
          />
        </div>
        <div
          v-else
          class="d-flex align-center justify-center outlined"
          style="border-radius: 4px; overflow: hidden; height: 100%"
        >
          <div class="d-flex flex-column align-center">
            <img src="../assets/asset.png" height="100vh" width="100vh" />
            <div class="caption grey--text">{{ $t("noAssetDashboard") }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="forecasts-container d-flex flex-column me-2">
        <v-card-title class="pa-0">
          {{ $t("forecastsDashboard") }}
        </v-card-title>
        <!-- TODO: actually show the predictions -->
        <WIP />
      </div>
    </template>

    <!-- Measures overview -->

    <div class="side-bar">
      <v-card v-if="!pureForecasts" class="grow-false pe-4" flat>
        <v-card-title class="d-flex flex-row flex-fill py-0">
          <span>{{ $t("realTime") }}</span>
          <v-spacer />
          <span class="caption me-1">{{ $t("lastDataTs") }}:</span>
          <span class="caption font-weight-bold">
            {{
              lastMessage != undefined
                ? getReadableTimestamp(lastMessage)
                : "--"
            }}
          </span>
          <Dot
            v-bind:color="
              isOffline[asset.boxMacAddress] == undefined
                ? 'grey'
                : isOffline[asset.boxMacAddress]
                ? 'error'
                : 'success'
            "
            v-bind:text="
              $t(
                isOffline[asset.boxMacAddress] == undefined
                  ? 'unknown'
                  : isOffline[asset.boxMacAddress]
                  ? 'offline'
                  : 'online'
              )
            "
            justify-end
          />
        </v-card-title>
        <v-card-text>
          <div row>
            <div
              v-for="header in asset.userInterface.header"
              v-bind:key="'asset-header-' + header.topic"
              v-bind:title="
                $t(header.topic) +
                ': ' +
                getMeasureValue(measures[header.topic]).readableTs()
              "
              class="d-flex align-center"
            >
              <v-icon class="me-2" color="primary">{{ header.icon }}</v-icon>
              <div>
                {{ getMeasureValue(measures[header.topic]).formattedContent() }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div class="grow-true measures-list-container">
        <div class="grow-true measures-list-scrollable pe-4">
          <div class="fixed-height-map rounded outlined">
            <Map v-bind:features="filteredCoords" ref="assetMap" />
          </div>

          <template
            v-if="asset.userInterface.maintenance.enabled && !pureForecasts"
          >
            <h3 class="mt-3">{{ $t("maintenance") }}</h3>
            <v-divider />

            <div
              v-if="asset.userInterface.maintenance.next"
              class="d-flex justify-space-between mt-3"
            >
              <span class="text-uppercase black--text">
                {{ $t("nextMaintenance") }}:
              </span>
              <span class="black--text">
                {{
                  getMeasureValue(
                    measures[asset.userInterface.maintenance.next]
                  ).formattedContent()
                }}
              </span>
            </div>

            <v-list dense>
              <v-list-item
                v-for="mTopic in asset.userInterface.maintenance.topics"
                v-bind:key="'asset-maintenance-' + mTopic.topic"
                class="px-0 mx-0"
                link
              >
                <v-list-item-avatar>
                  <v-avatar>
                    <v-progress-circular
                      v-bind:value="
                        valuePercentage(
                          mTopic.descending
                            ? getMeasureValue(measures[mTopic.topic]).content
                            : mTopic.target -
                                getMeasureValue(measures[mTopic.topic]).content,
                          mTopic.target
                        )
                      "
                      v-bind:color="
                        (mTopic.descending
                          ? getMeasureValue(measures[mTopic.topic]).content
                          : mTopic.target -
                            getMeasureValue(measures[mTopic.topic]).content) >
                        mTopic.target * 0.25
                          ? 'success'
                          : 'error'
                      "
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <p
                    class="text-uppercase caption primary--text text--lighten-2"
                  >
                    {{ $t(mTopic.topic) }}
                  </p>
                  <div class="body-1">
                    <span class="font-weight-bold">
                      {{
                        getMeasureValue(
                          measures[mTopic.topic]
                        ).formattedContent()
                      }}
                    </span>
                    {{ $t("separatorOf") }}
                    {{ mTopic.target }}km
                  </div>
                </v-list-item-content>
                <v-list-item-action-text>
                  {{
                    getMeasureValue(measures[mTopic.topic]).readableTs(false)
                  }}
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
          </template>

          <h3
            v-if="!!asset.boxMacAddress"
            class="d-flex justify-space-between align-baseline mt-3"
          >
            <span>{{ $t("mainParameters") }}</span>
            <v-btn
              v-on:click="
                // TODO: go to forecasts details when pureForecasts == true
                !pureForecasts
                  ? $router.push({ path: `/asset-more-details/${assetDbId}` })
                  : $bus.$emit('present-error', {
                      error: 'Work in progress',
                      description: 'This function is not implemented yet',
                    })
              "
              class="mb-2 caption font-weight-bold"
              color="primary"
              outlined
            >
              {{ $t("menuMoreDetails") }}
            </v-btn>
          </h3>
          <v-divider class="mb-3" />

          <!-- Bold -->
          <v-list-item
            v-for="measure in asset.userInterface.measures.highlights"
            v-bind:key="measure"
            class="px-0 mx-0"
            link
          >
            <v-list-item-content>
              {{ $t(measure) }}
            </v-list-item-content>
            <v-list-item-action class="title black--text font-weight-bold">
              <div class="d-flex align-center">
                <span
                  v-bind:class="
                    getMeasureValue(measures[measure]).getTextColor()
                  "
                >
                  {{ getMeasureValue(measures[measure]).formattedContent() }}
                </span>
                <v-icon
                  v-if="
                    getMeasureValue(measures[measure]).getIcon() != undefined
                  "
                  v-bind:color="getMeasureValue(measures[measure]).getIcon()[1]"
                  class="ms-3"
                  small
                >
                  {{ getMeasureValue(measures[measure]).getIcon()[0] }}
                </v-icon>
              </div>
            </v-list-item-action>
            <v-list-item-action-text class="ms-4">
              {{ getMeasureValue(measures[measure]).readableTs(false) }}
            </v-list-item-action-text>
          </v-list-item>
          <v-divider class="my-2 transparent" />
          <!-- Misc -->
          <v-list-item
            v-for="measure in asset.userInterface.measures.realtimes"
            v-bind:key="measure"
            class="px-0 mx-0"
            dense
            link
          >
            <v-list-item-content>
              {{ $t(measure) }}
            </v-list-item-content>
            <v-list-item-action class="body-1 black--text font-weight-bold">
              <div class="d-flex align-center">
                <span
                  v-bind:class="
                    getMeasureValue(measures[measure]).getTextColor()
                  "
                >
                  {{ getMeasureValue(measures[measure]).formattedContent() }}
                </span>
                <v-icon
                  v-if="
                    getMeasureValue(measures[measure]).getIcon() != undefined
                  "
                  v-bind:color="getMeasureValue(measures[measure]).getIcon()[1]"
                  class="ms-3"
                  small
                >
                  {{ getMeasureValue(measures[measure]).getIcon()[0] }}
                </v-icon>
              </div>
            </v-list-item-action>
            <v-list-item-action-text class="ms-4">
              {{ getMeasureValue(measures[measure]).readableTs(false) }}
            </v-list-item-action-text>
          </v-list-item>
        </div>
      </div>
    </div>

    <!-- Asset editing -->

    <v-dialog
      v-model="editAssetDialog"
      v-if="editAssetDialog"
      width="unset"
      persistent
      scrollable
    >
      <v-card style="min-width: 80ch">
        <v-card-title>
          {{ $t("btnEdit") }}
        </v-card-title>
        <v-card-text class="mt-2">
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="editAsset.boxMacAddress"
              v-bind:label="$t('assetMac')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="editAsset.plate"
              v-bind:label="$t('assetPlate')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-select
              v-if="asset.companyId == currentUser.companyId"
              v-model="editAsset.groupId"
              v-bind:items="groupsList"
              v-bind:label="$t('assetGroup')"
              v-bind:outlined="outlinedPref"
              item-text="name"
              item-value="id"
              class="mx-2"
              clearable
            />
          </v-form>

          <v-divider class="mb-2" />

          <v-form>
            <div
              class="caption font-weight-bold d-flex justify-space-between align-center"
            >
              {{ $t("assetCoords") }}
              <v-btn
                v-on:click="geocodingDialog = true"
                color="primary"
                text
                x-small
              >
                {{ $t("locationSearch") }}
              </v-btn>
            </div>

            <div class="d-flex" style="gap: 1em">
              <v-text-field
                v-model="editAsset.latitude"
                v-bind:label="$t('groupLat')"
                v-bind:outlined="outlinedPref"
                clearable
              />
              <v-text-field
                v-model="editAsset.longitude"
                v-bind:label="$t('groupLon')"
                v-bind:outlined="outlinedPref"
                clearable
              />
            </div>
          </v-form>

          <v-divider class="mb-2" />

          <v-form class="edit-asset-custom">
            <div
              v-for="field in ['assetImage', 'assetIcon']"
              v-bind:key="assetDbId + '-edit-' + field"
              class="d-flex flex-column justify-center align-center mb-8"
              style="border-bottom: 1px solid #1b1b1b84; padding-bottom: 0.5em"
            >
              <div
                class="d-flex justify-space-between align-center"
                style="width: 100%"
              >
                <span class="caption mb-2" style="pointer-events: none">
                  {{ $t(field) }}
                </span>

                <v-btn
                  v-on:click.stop="
                    editAsset.custom[field] = undefined;
                    delete editAsset.custom[field];
                  "
                  icon
                  small
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div
                class="edit-icon-hover"
                style="height: 7em; position: relative"
              >
                <img
                  v-if="editAsset.custom[field]"
                  v-bind:src="editAsset.custom[field]"
                  v-on:click.stop="loadAssetImage(field)"
                  style="cursor: pointer"
                  height="100%"
                />
                <img
                  v-else
                  v-on:click.stop="loadAssetImage(field)"
                  src="../assets/asset.png"
                  style="cursor: pointer"
                  height="100%"
                />
              </div>
            </div>
            <div
              v-for="field in editAsset.allCustoms([
                'assetImage',
                'assetIcon',
                'latitude',
                'longitude',
                'isForecast',
              ])"
              v-bind:key="assetDbId + '-edit-' + field"
            >
              <v-text-field
                v-model="editAsset.custom[field]"
                v-bind:label="$t(editAsset.trimInfluxPrefix(field))"
                v-bind:outlined="outlinedPref"
                v-bind:hint="tagIsList(field) ? $t('listSeparatorHint') : ''"
                v-bind:persistent-hint="tagIsList(field)"
              >
                <template v-slot:append>
                  <v-btn
                    v-on:click="
                      editAsset.custom[field] = undefined;
                      delete editAsset.custom[field];
                      if (!asset.listableCustoms().includes(field)) {
                        newCustomsCount -= 1;
                      }
                    "
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-form>

          <v-form class="mx-2">
            <v-btn
              v-on:click="
                editCustom = {};
                addCustomFieldDialog = true;
              "
              color="primary"
              block
              small
            >
              {{ $t("createCustomKey") }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetEdit" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validAsset"
            v-on:click="postAsset"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New custom field -->

    <v-dialog
      v-model="addCustomFieldDialog"
      v-if="addCustomFieldDialog"
      width="75ch"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("createCustomKey") }}</v-card-title>
        <v-card-text>
          <div class="d-flex" style="gap: 1em">
            <v-select
              v-model="editCustom.key"
              v-bind:items="assetDefaultAttrs"
              v-bind:label="$t('newCustomDefaultKey')"
              v-bind:outlined="outlinedPref"
              style="max-width: 35%"
              clearable
            >
              <template v-slot:item="{ item }">
                {{ $t(item) }}
              </template>
              <template v-slot:selection="{ item }">
                {{ $t(item) }}
              </template>
            </v-select>
            <v-text-field
              v-model="editCustom.key"
              v-bind:label="$t('newCustomKey')"
              v-bind:outlined="outlinedPref"
              clearable
            />
          </div>
          <div class="d-flex" style="gap: 3em">
            <v-text-field
              v-model="editCustom.value"
              v-bind:label="$t('value')"
              v-bind:outlined="outlinedPref"
              v-bind:hint="
                tagIsList(editCustom.key) ? $t('listSeparatorHint') : ''
              "
              v-bind:persistent-hint="tagIsList(editCustom.key)"
              clearable
            />
            <v-switch
              v-model="editCustom.influx"
              v-bind:label="$t('influxTag')"
              color="primary"
              inset
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="addCustomFieldDialog = false" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="
              editCustom.key == undefined ||
              editCustom.value == undefined ||
              editCustom.key == ''
            "
            v-on:click="
              editAsset.custom[
                (editCustom.influx ? influxTagPrefix : '') + editCustom.key
              ] = editCustom.value;
              newCustomsCount += 1;
              addCustomFieldDialog = false;
            "
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Location coordinates selection -->

    <v-dialog
      v-model="geocodingDialog"
      v-if="geocodingDialog"
      width="unset"
      persistent
      scrollable
    >
      <Geocoding
        v-bind:init="{
          lat: editAsset.latitude,
          lon: editAsset.longitude,
        }"
        v-on:close="geocodingDialog = false"
        v-on:geocoded="useGeocodingCoords"
      />
    </v-dialog>

    <!-- Commands dialog -->

    <AssetCommandsDialog
      v-if="displayCommands"
      v-bind:asset-id="assetDbId"
      v-bind:commands="assetCommands"
      v-on:close="displayCommands = false"
    />
  </div>
</template>

<style scoped>
.grid-container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 32%;
  grid-template-rows: 0.5fr 0.65fr 1fr;
  grid-template-areas:
    "asset side"
    "plot side"
    "dashboard side";
}

.asset-details {
  grid-area: asset;
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: auto 1fr 2fr auto;
}

.custom-properties-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.7em 3em;
}

.edit-asset-custom {
  padding: 1em;
  max-height: 35vh;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  gap: 0 2em;
}

.alarms-plot {
  grid-area: plot;
  display: flex;
  flex-direction: column;
}

.dashboard-container {
  position: relative;
  grid-area: dashboard;
}

.forecasts-container {
  position: relative;
  grid-row-start: plot;
  grid-row-end: dashboard;
}

.dashboard {
  position: absolute;
  inset: 0;
}

.side-bar {
  grid-area: side;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  overflow: hidden;
}

.measures-list-container {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  overflow: hidden;
}

.measures-list-scrollable {
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
}

.grow-false {
  flex: 0 1 0;
}

.grow-true {
  flex: 1 1 0;
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-height-map {
  height: 25vh;
}

.outlined {
  border: 1px solid #dfe7f0;
}

.customer-link {
  cursor: pointer;
  text-decoration: underline;
}

.edit-icon-hover:hover::after {
  content: "";
  cursor: pointer;
  background-color: #0c1d69;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z' /%3E%3C/svg%3E");
  background-size: 2em 2em;
  background-position: center;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

div[row] {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import Pages from "../utils/pages";
import Dot from "../components/Dot";
import AssetInfo from "../models/AssetInfo";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import HeatMap from "../components/HeatMap";
import Map from "../components/Map.vue";
import AssetEventsStatsRequestBody from "../models/AssetEventsStatsRequestBody";
import DynamicDashboard from "../components/DynamicDashboard";
import Geocoding from "../components/Geocoding";
import AssetCommandsDialog from "../components/AssetCommandsDialog";
import WIP from "../components/WIP";
import Event from "../models/Event";
import moment from "moment";

export default {
  name: "AssetDetails",

  components: {
    Dot,
    HeatMap,
    Map,
    DynamicDashboard,
    Geocoding,
    AssetCommandsDialog,
    WIP,
  },

  data: () => ({
    assetId: undefined,
    asset: new AssetInfo(),
    editAsset: new AssetInfo(),
    editAssetDialog: false,
    addCustomFieldDialog: false,
    editCustom: {},
    newCustomsCount: 0,
    searchTerm: undefined,
    eventsStats: [],
    loadingStats: false,
    color: "#ED553B",
    timeSlots: ["0-6", "6-12", "12-18", "18-24"],
    gpsTopics: {
      lon: "GPS/longitude",
      lat: "GPS/latitude",
      altLat: "Position/latitude",
      altLon: "Position/longitude",
    },
    influxTagPrefix: AssetInfo.INFLUX_TAG,
    geocodingDialog: false,
    newCoordinates: false,
    displayCommands: false,
  }),

  computed: {
    pureForecasts() {
      return (
        this.isOffline[this.asset.boxMacAddress] == undefined &&
        this.asset.isForecast
      );
    },
    assetDefaultAttrs() {
      return [
        ...AssetInfo.defaultAttributes,
        ...(this.emailActive ? AssetInfo.mailing : []),
      ];
    },
    crumbs() {
      return this.$store.getters.pages;
    },
    measures() {
      const ms = this.$store.getters.measuresById(this.assetId);
      return ms === undefined ? {} : ms;
    },
    lastMessage() {
      return this.lastSeenBySerial(this.assetId);
    },
    assetDbId() {
      return this.$route.params.assetId;
    },
    topics() {
      return Object.keys(this.asset.customComponent.values || {});
    },
    filteredCoords() {
      let lat =
        this.measures[this.gpsTopics.lat]?.value?.content ||
        this.measures[this.gpsTopics.altLat]?.value?.content;
      let lon =
        this.measures[this.gpsTopics.lon]?.value?.content ||
        this.measures[this.gpsTopics.altLon]?.value?.content;
      const ts =
        this.measures[this.gpsTopics.lat]?.value?.ts ||
        this.measures[this.gpsTopics.altLat]?.value?.ts ||
        this.asset.modified?.toDate()?.getTime();

      if (!lat && !lon && !!this.asset.latitude && !!this.asset.longitude) {
        lat = Number(this.asset.latitude);
        lon = Number(this.asset.longitude);
      }

      return !!lat && !!lon && !isNaN(lat) && !isNaN(lon)
        ? [
            {
              coords: [lon, lat],
              ts,
              // eslint-disable-next-line prettier/prettier
              name: `${this.asset.brand} ${this.asset.model}${this.asset.version ? " " + this.asset.version : ""}`,
              id: this.asset.id,
              serial: this.asset.serial,
            },
          ]
        : [];
    },
    validAsset() {
      return (
        this.asset.boxMacAddress !== this.editAsset.boxMacAddress ||
        this.asset.plate !== this.editAsset.plate ||
        this.asset.groupId !== this.editAsset.groupId ||
        this.newCoordinates ||
        this.newCustomsCount > 0 ||
        this.asset.allCustoms().reduce((result, key) => {
          return (
            result || this.editAsset.custom[key] !== this.asset.custom[key]
          );
        }, false) ||
        this.editAsset.allCustoms().reduce((result, key) => {
          return (
            result || this.editAsset.custom[key] !== this.asset.custom[key]
          );
        }, false)
      );
    },
    commandsList() {
      return this.$store.getters.allCommands;
    },
    assetCommands() {
      return this.commandsList.filter((cmd) => {
        const applyToAllAssets =
          (cmd.assetIds || []).length === 0 &&
          (cmd.assetEntityCodes || []).length === 0;
        const userEnabled =
          (cmd.userIds || []).length === 0 ||
          cmd.userIds.includes(this.currentUser.id);
        const assetEnabled = (cmd.assetIds || []).includes(this.asset.id);
        const ecEnabled = (cmd.assetEntityCodes || []).includes(
          this.asset.entityCode
        );

        return (
          cmd.enabled &&
          userEnabled &&
          (applyToAllAssets || assetEnabled || ecEnabled)
        );
      });
    },
  },

  async beforeMount() {
    try {
      await this.fetchAssetData();
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.error(err);
      this.$router.replace({ path: "/dashboard" });
      this.$bus.$emit(Messages.ERROR_MSG, {
        error: err?.response?.data?.error || err,
        description: this.$t("assetFetchFailure").replace(
          "%serial%",
          this.assetId
        ),
      });
      return;
    }

    this.updateFiltersRules(this.clientId);
    this.fetchHeatMapData();
    this.$store.commit("setCurrentPage", Pages.DETAILS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuDetails"),
      to: `/asset-details/${this.assetDbId}`,
      root: false,
    });
  },

  beforeDestroy() {
    // Clear the asset measures for memory optimization
    this.$store.commit("clearMeasure", this.asset.boxMacAddress);
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue, false);
    },
    assetDbId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchAssetData();
        this.updateFiltersRules(this.clientId);
      }
    },
    filteredCoords: {
      deep: true,
      handler(newValue) {
        this.$refs.assetMap.setBoundingBox(newValue, true);
      },
    },
  },

  methods: {
    tagIsList(tag = undefined) {
      return AssetInfo.mailing.includes(tag ?? this.editCustom.key);
    },
    valuePercentage(value, max) {
      return (100 * value) / max;
    },
    async fetchAssetData() {
      this.asset = new AssetInfo(
        (await ApiRequests.getAsset(this.assetDbId, undefined, undefined)).data
      );

      this.assetId = this.asset.serial;
      this.asset.companyName = this.customersList.find(
        (c) => c.id === this.asset.companyId
      )?.name;
      this.asset.groupName = this.groupsList.find(
        (f) => f.id === this.asset.groupId
      )?.name;
    },
    openEditDialog() {
      this.editAsset = new AssetInfo(JSON.parse(this.asset.original));
      this.editAssetDialog = true;
    },
    postAsset() {
      if (this.currentUser.companyId != this.editAsset.companyId) {
        this.editAsset.groupId = undefined;
      }

      const updateBody = this.editAsset.getAssetUpdateBody();

      ApiRequests.updateAsset(
        this.editAsset.id,
        updateBody,
        () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("assetUpdateSuccess"));
          this.resetEdit();
          this.fetchAssetData();
          this.fetchAllData();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("assetUpdateFailure"),
          });
        }
      );
    },
    resetEdit() {
      this.editAssetDialog = false;
      this.newCoordinates = false;
      this.editAsset = new AssetInfo();
    },
    fetchHeatMapData() {
      this.loadingStats = true;

      const statsRequest = new AssetEventsStatsRequestBody();

      statsRequest.severities = [
        Event.severity.LOW,
        Event.severity.MEDIUM,
        Event.severity.HIGH,
      ];
      statsRequest.serials = [this.asset.serial];
      statsRequest.groupInterval = "day";
      statsRequest.starting = moment().subtract(1, "month").format();
      statsRequest.groupTimeSlots = this.timeSlots;

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          if (res.data.length > 0) {
            const tmpRanges = [];

            res.data.forEach((e) => {
              if (!tmpRanges.includes(e.period)) tmpRanges.push(e.period);
            });

            this.eventsStats = res.data.map((e) => ({
              x: moment(e.period, "YYYY-MM-DD").format("DD MMM YYYY"),
              y: e.timeSlot,
              v: e.count,
            }));
          }

          this.loadingStats = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingStats = false;
        }
      );
    },
    updateFiltersRules(id, getMeasures = true) {
      if (id !== undefined) {
        const topicsList = [
          ...(this.asset.userInterface.header || []).map((h) => h.topic),
          ...(this.asset.userInterface.maintenance.enabled
            ? (this.asset.userInterface.maintenance.topics || []).map(
                (t) => t.topic
              )
            : []),
          ...(this.asset.userInterface.measures.highlights || []),
          ...(this.asset.userInterface.measures.realtimes || []),
        ];

        Object.keys(this.gpsTopics).forEach((key) =>
          topicsList.push(this.gpsTopics[key])
        );

        ApiRequests.createOrUpdateFilter(
          id,
          [
            ...topicsList.map(
              (v) => `^(measures/@${this.asset.boxMacAddress}/${v})$`
            ),
            ...this.topics.map(
              (t) => `measures/@${this.asset.boxMacAddress}/${t}`
            ),
            ...this.signalRBaseRegexes,
          ],
          () => {
            if (!getMeasures) return;

            const requestBody = new AssetDataRequestBody();

            requestBody.ids = [this.assetDbId];
            requestBody.paths = [
              ...topicsList.map((t) => `measures/${t}`),
              ...this.topics.map((t) => `measures/${t}`),
            ];
            requestBody.includeMetadata = true;

            ApiRequests.getSavedMeasures(
              requestBody,
              undefined,
              (res) => {
                const requestedData = res.data[this.assetDbId];

                Object.keys(requestedData).forEach((k) => {
                  this.$store.dispatch("saveMessage", requestedData[k]);
                });

                this.$refs.assetMap.setBoundingBox(this.filteredCoords, false);
                this.$refs.dashboard?.computeBody();
              },
              (err) =>
                process.env.NODE_ENV === "development"
                  ? console.error(err)
                  : undefined
            );
          },
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    loadAssetImage(field) {
      const tmpInput = document.createElement("input");
      const imageHeights = {
        assetImage: 128,
        assetIcon: 64,
      };

      tmpInput.type = "file";
      tmpInput.accept = "image/*";

      tmpInput.addEventListener("change", () => {
        if (tmpInput.files && tmpInput.files[0]) {
          const img = new Image();

          img.onload = () => {
            const tmpCanvas = document.createElement("canvas");
            const tmpCtx = tmpCanvas.getContext("2d");

            tmpCanvas.height = imageHeights[field];
            tmpCanvas.width = tmpCanvas.height * (img.width / img.height);

            tmpCtx.drawImage(img, 0, 0, tmpCanvas.width, tmpCanvas.height);

            this.editAsset.custom[field] = tmpCanvas.toDataURL();
          };

          img.src = URL.createObjectURL(tmpInput.files[0]);
        }
      });

      tmpInput.click();
    },
    useGeocodingCoords(coords) {
      this.editAsset.latitude = coords.lat;
      this.editAsset.longitude = coords.lon;
      this.geocodingDialog = false;
      this.newCoordinates = true;
    },
  },
};
</script>
