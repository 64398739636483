<template>
  <div
    v-bind:class="{
      'pb-4': true,
      'pt-0': true,
      'ps-4': true,
      'pe-0': true,
      'fill-height': true,
      'grid-container': tab == 0,
      'grid-container-alt': tab > 0,
    }"
  >
    <!-- FILTERS -->
    <v-card v-if="tab > 0" class="slide-in side-bar no-overflow" flat>
      <div
        class="pe-4"
        flex-row
        style="justify-content: space-between; align-items: center"
      >
        <v-card-title class="px-0"> {{ $t("filters") }} </v-card-title>
        <v-btn v-on:click="resetFilters" color="primary" small text>
          {{ $t("btnRemoveFilters") }}
        </v-btn>
      </div>

      <div class="grow-true scrollable pe-4 pt-1 filters-flex">
        <v-text-field
          v-model="searchTerm"
          v-bind:outlined="outlinedPref"
          v-bind:label="$t('searchPlaceholder')"
          prepend-inner-icon="mdi-magnify"
          clearable
          hide-details
        />

        <div class="checkers px-3" flex-row>
          <v-radio-group
            class="ma-0 pa-0 half-checker"
            v-model="filters.status"
          >
            <v-card-text class="ps-0 pt-0">
              {{ $t("assetStatus") }}
            </v-card-text>
            <v-radio v-bind:label="$t('all')" value="all" />
            <v-radio v-bind:label="$t('online')" value="online" />
            <v-radio v-bind:label="$t('offline')" value="offline" />
            <v-radio
              v-if="predictionAssetsCount > 0"
              v-bind:label="$t('forecastOnlyAsset')"
              value="prediction"
            />
          </v-radio-group>

          <v-radio-group
            class="ma-0 pa-0 half-checker"
            v-model="filters.alarms"
          >
            <v-card-text class="ps-0 pt-0">
              {{ $t("assetAlarms") }}
            </v-card-text>
            <v-radio v-bind:label="$t('all')" value="all" />
            <v-radio v-bind:label="$t('withAlarms')" value="withAlarms" />
            <v-radio v-bind:label="$t('withNoAlarms')" value="noAlarms" />
          </v-radio-group>
        </div>

        <div flex-row>
          <v-select
            v-model="filters.type"
            v-bind:items="Object.keys(types)"
            v-bind:label="$t('assetType')"
            v-bind:outlined="outlinedPref"
            class="small-select"
            clearable
            hide-details
          />
          <v-select
            v-model="filters.subType"
            v-bind:items="types[filters.type]"
            v-bind:label="$t('assetSubType')"
            v-bind:outlined="outlinedPref"
            v-bind:no-data-text="$t('assetTypeNoData')"
            class="small-select"
            clearable
            hide-details
          />
        </div>

        <div flex-row>
          <v-select
            v-model="filters.brand"
            v-bind:items="Object.keys(models)"
            v-bind:label="$t('assetBrand')"
            v-bind:outlined="outlinedPref"
            class="small-select"
            clearable
            hide-details
          />
          <v-select
            v-model="filters.model"
            v-bind:items="models[filters.brand]"
            v-bind:label="$t('assetModel')"
            v-bind:outlined="outlinedPref"
            v-bind:no-data-text="$t('assetModelNoData')"
            class="small-select"
            clearable
            hide-details
          />
        </div>

        <v-select
          v-model="filters.group"
          v-bind:items="groupsList"
          v-bind:disabled="!!filters.customer"
          item-text="name"
          item-value="name"
          v-bind:label="$t('group')"
          v-bind:outlined="outlinedPref"
          clearable
          hide-details
        />

        <v-select
          v-model="filters.customer"
          v-bind:items="customersList"
          v-bind:disabled="!!filters.group"
          item-text="name"
          item-value="name"
          v-bind:label="$t('menuCustomers')"
          v-bind:outlined="outlinedPref"
          clearable
          hide-details
        />
      </div>
    </v-card>

    <!-- DASHBOARD -->
    <v-card class="content-header" elevation="0" tile>
      <v-card-title class="py-1">
        <v-btn
          v-if="tab === 0"
          v-bind:disabled="
            loadingAlarms.threeDays ||
            loadingAlarms.overview ||
            loadingAlarms.high ||
            loadingAlarms.medium ||
            loadingAlarms.low
          "
          v-on:click="refresher"
          class="me-3"
          color="primary"
          icon
          small
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        {{ $t("assets") }}

        <template v-if="tab === 0">
          <v-btn
            v-on:click="scrollTo($refs.assetsSection)"
            class="ms-12"
            color="primary"
            small
            text
          >
            {{ $t("assetsStatisticsSection") }}
          </v-btn>
          <v-btn
            v-on:click="scrollTo($refs.alarmsSection)"
            color="primary"
            small
            text
          >
            {{ $t("alarmsStatisticsSection") }}
          </v-btn>
        </template>
      </v-card-title>
      <v-spacer />
      <v-card-actions class="pa-0">
        <v-tabs v-model="tab" class="outlined-tabs">
          <v-tab><v-icon>mdi-chart-areaspline</v-icon></v-tab>
          <v-tab><v-icon>mdi-map-marker-radius-outline</v-icon></v-tab>
        </v-tabs>
      </v-card-actions>
    </v-card>

    <v-tabs-items v-model="tab" class="no-overflow">
      <v-tab-item
        class="pa-1 inner-grid-container scrollable"
        ref="dashboardBlocks"
      >
        <TextSeparator
          v-bind:text="$t('assetsStatisticsSection')"
          class="full-card"
          text-color="grey"
          ref="assetsSection"
        />

        <!-- Assets statistics -->

        <v-card class="long-card" elevation="2">
          <v-card-title class="text-wrap">
            {{ $t("assetsByEntityCode") }}
            <v-spacer />
            <v-icon
              v-if="loadingAssets.entityCodes"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text>
            <CategoryBars
              v-bind:data="stats.entityCodes"
              v-bind:labels-rotation="45"
              v-bind:x-top="true"
              dataType="assets"
            />
          </v-card-text>
        </v-card>

        <v-card elevation="2">
          <v-card-title class="text-wrap">
            {{ $t("onlineAssets") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <div class="asset-state-item body-1">
              {{ $t("assets") }}:
              <div class="font-weight-bold">
                {{ assetsCount }}
              </div>
            </div>
            <div class="my-4">
              <div class="asset-state-item">
                {{ $t("online") }}:
                <div class="font-weight-bold">
                  {{ onlineAssetsCount }}
                  ({{ (100 * (onlineAssetsCount / assetsCount)).toFixed(0) }}%)
                </div>
              </div>
              <v-divider />
              <div class="asset-state-item">
                {{ $t("offline") }}:
                <div class="font-weight-bold">
                  {{ offlineAssetsCount }}
                  ({{ (100 * (offlineAssetsCount / assetsCount)).toFixed(0) }}%)
                </div>
              </div>
              <v-divider />
              <template v-if="predictionAssetsCount > 0">
                <div class="asset-state-item">
                  {{ $t("prediction") }}:
                  <div class="font-weight-bold">
                    {{ predictionAssetsCount }}
                    ({{
                      (100 * (predictionAssetsCount / assetsCount)).toFixed(0)
                    }}%)
                  </div>
                </div>
                <v-divider />
              </template>
              <div class="asset-state-item">
                {{ $t("unknown") }}:
                <div class="font-weight-bold">
                  {{ unknownAssetsCount }}
                  ({{ (100 * (unknownAssetsCount / assetsCount)).toFixed(0) }}%)
                </div>
              </div>
              <v-divider />
            </div>
            <Pie
              v-bind:data="[
                { category: $t('online'), value: onlineAssetsCount },
                { category: $t('offline'), value: offlineAssetsCount },
                ...(predictionAssetsCount > 0
                  ? [
                      {
                        category: $t('prediction'),
                        value: predictionAssetsCount,
                      },
                    ]
                  : []),
                { category: $t('unknown'), value: unknownAssetsCount },
              ]"
              v-bind:colors="onlineColors"
              v-bind:legend-clickable="false"
              dataType="assets"
              animationStyle="none"
              style="max-height: 15rem"
            />
          </v-card-text>
        </v-card>

        <TextSeparator
          v-bind:text="$t('alarmsStatisticsSection')"
          class="full-card"
          text-color="grey"
          ref="alarmsSection"
        />

        <!-- Alarms statistics -->

        <v-card
          v-on:click="
            $router.replace({
              path: `/alarms`,
              query: { severity: severities.HIGH },
            })
          "
          class="alert-card-color"
          elevation="2"
        >
          <v-card-title class="text-wrap">
            {{ $t("alarmsCriticalCategory") }} {{ $t("thisDay") }}
            <v-spacer />
            <v-icon
              v-if="loadingAlarms.high"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text class="black--text">
            <div class="text-h3 font-weight-bold">
              {{ stats.high != undefined ? stats.high : "--" }}
            </div>
            <div class="font-weight-bold text-uppercase error--text">
              {{ $t("alarmsCriticalCategory") }}
            </div>
          </v-card-text>
        </v-card>

        <v-card
          v-on:click="
            $router.replace({
              path: `/alarms`,
              query: { severity: severities.MEDIUM },
            })
          "
          class="warning-card-color"
          elevation="2"
        >
          <v-card-title class="text-wrap">
            {{ $t("alarmsMediumCategory") }} {{ $t("thisDay") }}
            <v-spacer />
            <v-icon
              v-if="loadingAlarms.medium"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text class="black--text">
            <div class="text-h3 font-weight-bold">
              {{ stats.medium != undefined ? stats.medium : "--" }}
            </div>
            <div class="font-weight-bold text-uppercase warning--text">
              {{ $t("alarmsMediumCategory") }}
            </div>
          </v-card-text>
        </v-card>

        <v-card
          v-on:click="
            $router.replace({
              path: `/alarms`,
              query: { severity: severities.LOW },
            })
          "
          class="medium-card-color"
          elevation="2"
        >
          <v-card-title class="text-wrap">
            {{ $t("alarmsLowCategory") }} {{ $t("thisDay") }}
            <v-spacer />
            <v-icon
              v-if="loadingAlarms.low"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text class="black--text">
            <div class="text-h3 font-weight-bold">
              {{ stats.low != undefined ? stats.low : "--" }}
            </div>
            <div class="font-weight-bold text-uppercase teal--text">
              {{ $t("alarmsLowCategory") }}
            </div>
          </v-card-text>
        </v-card>

        <v-card elevation="2">
          <v-card-title class="text-wrap">
            {{ $t("alarms3D") }}
            <v-spacer />
            <v-icon
              v-if="loadingAlarms.threeDays"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text class="pa-4">
            <Pie
              v-bind:data="stats.threeDays"
              v-bind:colors="alarmColors"
              dataType="events"
              class="data-chart"
            />
          </v-card-text>
        </v-card>

        <v-card class="long-card" elevation="2">
          <v-card-title class="text-wrap">
            {{ $t("alarmsTitle") }}
            <v-spacer />
            <v-icon
              v-if="loadingAlarms.overview"
              class="ms-2 spinning"
              color="primary"
              icon
              small
            >
              mdi-sync
            </v-icon>
          </v-card-title>
          <v-card-text class="pa-4">
            <Bars
              v-bind:data="stats.overview"
              v-bind:colors="alarmColors"
              class="data-chart"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item class="map-container">
        <div
          class="rounded outlined grow-true"
          style="max-height: 63vh; min-height: 63vh"
        >
          <GeoMap
            v-bind:features="filteredCoords"
            v-bind:selected="carouselSelected"
            v-bind:selectedGroup="carouselSelectedGroup"
            v-on:map-feature-clicked="handleClick"
            v-on:map-group-clicked="openGroup"
            ref="assetMap"
          />
        </div>
        <v-card class="asset-carousel" flat>
          <v-card
            v-for="asset in filteredAssets"
            v-bind:key="asset.serial"
            v-bind:outlined="carouselSelected != asset.serial"
            v-bind:style="
              carouselSelected == asset.serial
                ? 'border: 2px solid #0c1d69'
                : undefined
            "
            v-on:click="handleClick(asset, true)"
            flat
          >
            <div
              class="font-weight-bold mb-3"
              v-bind:title="
                (asset.plate || asset.boxMacAddress || $t('assetNoPlate')) +
                // eslint-disable-next-line prettier/prettier
                ' (' + asset.brand + ' - ' + asset.model + ')'
              "
            >
              <div class="pt-2" style="display: flex; justify-content: center">
                <img
                  v-if="asset.image"
                  v-bind:src="asset.image || '../assets/asset.png'"
                  height="62vh"
                  width="auto"
                />
                <img
                  v-else
                  src="../assets/asset.png"
                  height="62vh"
                  width="auto"
                />
              </div>
              <v-card-title class="pb-1" flat>
                <strong class="subtitle-2" v-if="!!asset.plate">
                  {{ asset.plate }}
                </strong>
                <strong class="subtitle-2" v-else>
                  {{ asset.boxMacAddress || $t("assetNoPlate") }}
                </strong>
              </v-card-title>
              <v-card-text class="py-0 body-2 text-truncate">
                {{ asset.brand }} - {{ asset.model }}
              </v-card-text>
              <v-card-text>
                <div class="d-flex flex-column justify-center align-start">
                  <div
                    v-for="measure in asset.measuresSummary"
                    v-bind:key="asset.id + '-dh-' + measure"
                    v-bind:title="
                      $t(asset.highlightName(measure)) +
                      ': ' +
                      getMeasureValue(
                        availableMeasures[asset.boxMacAddress][measure] || {}
                      ).formattedContent()
                    "
                    class="caption d-flex justify-space-between highlighted-measure"
                    style="width: 100%"
                  >
                    <span class="me-1 text-truncate" style="max-width: 55%">
                      {{ $t(asset.highlightName(measure)) }}
                    </span>
                    <span
                      v-bind:class="
                        'font-weight-bold text-truncate ms-2 ' +
                        getMeasureValue(
                          availableMeasures[asset.boxMacAddress][measure] || {}
                        ).getTextColor()
                      "
                      style="max-width: 44%"
                    >
                      {{
                        getMeasureValue(
                          availableMeasures[asset.boxMacAddress][measure] || {}
                        ).formattedContent()
                      }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </div>
          </v-card>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style scoped>
.grid-container {
  position: absolute;
  display: grid;
  gap: 0.5rem;
  inset: 0;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header"
    "content";
}

.grid-container-alt {
  position: absolute;
  display: grid;
  gap: 0.5rem;
  inset: 0;
  grid-template-columns: 25% 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "side header"
    "side content";
}

*[flex-row] {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.data-chart {
  height: 22rem;
}

.side-bar {
  grid-area: side;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.filters-flex {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.filters-flex > * {
  flex-grow: 0;
}

.checkers {
  align-items: flex-start;
  justify-content: flex-start;
}

.half-checker {
  width: 45%;
}

.no-overflow {
  overflow: hidden;
  height: 100%;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.small-select {
  flex: 1 0 16ch;
}

.grow-true {
  flex: 1 1 0;
}

.inner-grid-container {
  grid-area: content;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
}

.content-header {
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  overflow: hidden;
}

.alert-card-color {
  border-color: #ed553b;
  border-style: solid;
  background-color: rgba(237, 85, 59, 0.1);
}

.warning-card-color {
  border-color: #f6d55c;
  border-style: solid;
  background-color: rgba(246, 213, 92, 0.1);
}

.medium-card-color {
  border-color: #3caea3;
  border-style: solid;
  background-color: rgba(60, 174, 163, 0.1);
}

.text-wrap {
  word-wrap: normal;
}

.long-card {
  grid-column: span 2;
}

.full-card {
  grid-column: span 3;
}

.asset-state-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-container {
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
}

.asset-carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 12rem;
  gap: 1em;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 0.2em;
  flex-grow: 1;
}

.outlined {
  border: 1px solid #dfe7f0;
}

.highlighted-measure:hover {
  background: #0c1d6942;
}

.slide-in {
  animation: slide 0.4s forwards;
}

@keyframes slide {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0;
  }
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import GeoMap from "../components/Map.vue";
import AssetEventsStatsRequestBody from "../models/AssetEventsStatsRequestBody";
import AssetEventsStats from "../models/AssetEventsStats";
import Event from "../models/Event";
import Pie from "../components/Pie";
import Bars from "../components/Bars";
import CategoryBars from "../components/CategoryBars";
import TextSeparator from "../components/TextSeparator";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import moment from "moment";

export default {
  name: "Dashboard",

  components: { GeoMap, Pie, Bars, CategoryBars, TextSeparator },

  data: () => ({
    tab: 0,
    searchTerm: undefined,
    carouselSelected: undefined,
    carouselSelectedGroup: undefined,
    severities: Event.severity,
    alarmColors: {},
    onlineColors: {},
    highlightedMeasures: [],
    gpsTopics: {
      lon: "GPS/longitude",
      lat: "GPS/latitude",
      altLat: "Position/latitude",
      altLon: "Position/longitude",
    },
    loadingAlarms: {
      threeDays: false,
      overview: false,
      high: false,
      medium: false,
      low: false,
    },
    loadingAssets: {
      entityCodes: false,
    },
    stats: {
      entityCodes: [],
      threeDays: [],
      overview: [],
      high: undefined,
      medium: undefined,
      low: undefined,
    },
    filters: {
      status: "all",
      alarms: "all",
      brand: undefined,
      model: undefined,
      group: undefined,
      customer: undefined,
      type: undefined,
      subType: undefined,
    },
  }),

  beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.fetchHighlights();
    this.refresher();
    this.$store.commit("setCurrentPage", Pages.HOME_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
    this.tab = this.$store.getters.dashboardTab;

    this.alarmColors = {
      [this.$t(Event.severity.LOW)]: "#3CAEA3",
      [this.$t(Event.severity.MEDIUM)]: "#F6D55C",
      [this.$t(Event.severity.HIGH)]: "#ED553B",
    };
    this.onlineColors = {
      [this.$t("online")]: "#4CAF50",
      [this.$t("offline")]: "#FF5252",
      [this.$t("prediction")]: "#3F51B5",
      [this.$t("unknown")]: "#9E9E9E",
    };
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuHome"),
      to: "/dashboard",
      root: true,
    });
  },

  computed: {
    models() {
      return this.$store.getters.models;
    },
    types() {
      return this.$store.getters.types;
    },
    assetsCount() {
      return this.assetsList.filter((asset) => asset.boxMacAddress).length;
    },
    onlineAssetsCount() {
      return this.assetsList
        .filter((asset) => asset.boxMacAddress)
        .filter((asset) => !(this.isOffline[asset.boxMacAddress] ?? true))
        .length;
    },
    offlineAssetsCount() {
      return this.assetsList
        .filter((asset) => asset.boxMacAddress)
        .filter((asset) => this.isOffline[asset.boxMacAddress] ?? false).length;
    },
    predictionAssetsCount() {
      return this.assetsList
        .filter((asset) => asset.boxMacAddress)
        .filter(
          (asset) =>
            this.isOffline[asset.boxMacAddress] == undefined && asset.isForecast
        ).length;
    },
    unknownAssetsCount() {
      return (
        this.assetsCount -
        (this.onlineAssetsCount +
          this.offlineAssetsCount +
          this.predictionAssetsCount)
      );
    },
    filteredAssets() {
      return this.assetsList
        .filter((asset) => asset.boxMacAddress)
        .filter((asset) => asset.matchSearchTerm(this.searchTerm))
        .filter((asset) => asset.matchSearchTerm(this.filters.brand))
        .filter((asset) => asset.matchSearchTerm(this.filters.model))
        .filter((asset) => asset.matchSearchTerm(this.filters.group))
        .filter((asset) => asset.matchSearchTerm(this.filters.customer))
        .filter((asset) => asset.matchSearchTerm(this.filters.type))
        .filter((asset) => asset.matchSearchTerm(this.filters.subType))
        .filter((asset) => {
          const alarmsCount = this.alarmsCountById[asset.id];

          switch (this.filters.alarms) {
            case "withAlarms":
              return (
                (alarmsCount?.low || 0) > 0 ||
                (alarmsCount?.medium || 0) > 0 ||
                (alarmsCount?.high || 0) > 0
              );
            case "noAlarms":
              return (
                (alarmsCount?.low || 0) === 0 &&
                (alarmsCount?.medium || 0) === 0 &&
                (alarmsCount?.high || 0) === 0
              );
            default:
              return true;
          }
        })
        .filter((asset) => {
          switch (this.filters.status) {
            case "online":
              return !(this.isOffline[asset.boxMacAddress] ?? true);
            case "offline":
              return this.isOffline[asset.boxMacAddress] ?? true;
            case "prediction":
              return asset.isForecast;
            default:
              return true;
          }
        });
    },
    filteredCoords() {
      const mapGroups = [];

      return this.filteredAssets
        .map((asset) => {
          if (asset.groupId != undefined && asset.groupId != "") {
            // This asset has a group, draw it on the map instead (if it has coordinates)
            // eslint-disable-next-line prettier/prettier
            const assetGroup = this.groupsList.find((g) => g.id === asset.groupId);
            const groupLon = Number(assetGroup?.lon || undefined);
            const groupLat = Number(assetGroup?.lat || undefined);

            if (!isNaN(groupLat) && !isNaN(groupLon)) {
              if (mapGroups.includes(assetGroup.id)) return;

              mapGroups.push(assetGroup.id);

              return {
                coords: [groupLon, groupLat],
                name: assetGroup.name,
                id: assetGroup.id,
                isGroup: true,
              };
            }
          }

          const measures = this.$store.getters.measuresById(asset.serial);
          let lat =
            measures[this.gpsTopics.lat]?.value?.content ||
            measures[this.gpsTopics.altLat]?.value?.content;
          let lon =
            measures[this.gpsTopics.lon]?.value?.content ||
            measures[this.gpsTopics.altLon]?.value?.content;
          const ts =
            measures[this.gpsTopics.lat]?.value?.ts ||
            measures[this.gpsTopics.altLat]?.value?.ts ||
            asset.modified?.toDate()?.getTime();

          if (!lat && !lon && !!asset.latitude && !!asset.longitude) {
            lat = Number(asset.latitude);
            lon = Number(asset.longitude);
          }

          return !!lat && !!lon && !isNaN(lat) && !isNaN(lon)
            ? {
                coords: [lon, lat],
                ts,
                name: asset.plate || asset.serial,
                id: asset.id,
                serial: asset.serial,
                isGroup: false,
              }
            : undefined;
        })
        .filter((gps) => gps !== undefined);
    },
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue, false);
    },
    tab(newValue) {
      this.$store.commit("setDashboardTab", newValue);
    },
    assetsList: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length > 0 && newValue.length > oldValue.length) {
          this.fetchHighlights();
        }
      },
    },
    filteredCoords: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length === 1) {
          this.$refs.assetMap.setBoundingBox(
            newValue,
            oldValue.length === newValue.length
          );
        } else if (newValue.length > 1 && oldValue.length === 1) {
          this.$refs.assetMap.setBoundingBox(newValue, false);
        }
      },
    },
  },

  methods: {
    refresher() {
      this.fetchAlarms();
      this.fetchEntityCodes();
    },
    updateFiltersRules(id, getMeasures = true) {
      if (id !== undefined) {
        const topicsList = Object.keys(this.gpsTopics).map(
          (k) => this.gpsTopics[k]
        );

        ApiRequests.createOrUpdateFilter(
          id,
          [
            ...this.signalRBaseRegexes,
            ...topicsList.map((v) => `^measures/@[^@$/]+/${v}`),
            ...this.highlightedMeasures,
          ],
          () => {
            if (!getMeasures) return;

            const requestBody = new AssetDataRequestBody();

            requestBody.paths = topicsList.map((t) => `measures/${t}`);
            requestBody.includeMetadata = false;

            ApiRequests.getSavedMeasures(
              requestBody,
              undefined,
              (res) => {
                Object.keys(res.data).forEach((assetDbId) => {
                  const requestedData = res.data[assetDbId];

                  Object.keys(requestedData).forEach((k) => {
                    this.$store.dispatch("saveMessage", requestedData[k]);
                  });
                });
              },
              (err) =>
                process.env.NODE_ENV === "development"
                  ? console.error(err)
                  : undefined
            );
          },
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    fetchHighlights() {
      Promise.allSettled(
        this.assetsList
          .filter((asset) => asset.boxMacAddress)
          .map((asset) => {
            const highlights = asset.userInterface.measures?.highlights || [];
            const requestBody = new AssetDataRequestBody();

            requestBody.ids = [asset.id];
            requestBody.paths = highlights.map((t) => {
              const newHighlighted = `measures/@[^@$/]+/${t}`;

              if (!this.highlightedMeasures.includes(newHighlighted)) {
                this.highlightedMeasures.push(newHighlighted);
              }

              return `measures/${t}`;
            });
            requestBody.includeMetadata = true;

            return requestBody.paths.length > 0
              ? ApiRequests.getSavedMeasures(
                  requestBody,
                  undefined,
                  undefined,
                  undefined
                )
              : Promise.resolve();
          })
      )
        .then((results) => {
          results.forEach((result) => {
            if (result.status === "fulfilled" && result.value != undefined) {
              const configData = JSON.parse(result.value.config.data);
              const requestedData = result.value.data[configData.ids[0]];

              Object.keys(requestedData).forEach((k) => {
                this.$store.dispatch("saveMessage", requestedData[k]);
              });
            }
          });

          this.updateFiltersRules(this.clientId);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
        });
    },
    fetchAlarms() {
      this.loadingAlarms = {
        threeDays: true,
        overview: true,
        high: true,
        medium: true,
        low: true,
      };

      let statsRequest = new AssetEventsStatsRequestBody();

      // Fetch 3 days count
      statsRequest.severities = [
        Event.severity.HIGH,
        Event.severity.MEDIUM,
        Event.severity.LOW,
      ];
      statsRequest.groupBySeverity = true;
      statsRequest.groupInterval = "day";
      statsRequest.starting = moment().subtract(3, "days").format();

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          this.stats.threeDays = Object.values(
            res.data
              .map((e) => new AssetEventsStats(e))
              .reduce((acc, e) => {
                if (!acc[e.severity])
                  acc[e.severity] = { category: this.$t(e.severity), value: 0 };

                acc[e.severity].value += e.count;

                return acc;
              }, {})
          );

          this.loadingAlarms.threeDays = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAlarms.threeDays = false;
        }
      );

      // Fetch month overview
      statsRequest = new AssetEventsStatsRequestBody();

      statsRequest.severities = [
        Event.severity.HIGH,
        Event.severity.MEDIUM,
        Event.severity.LOW,
      ];
      statsRequest.groupBySeverity = true;
      statsRequest.groupInterval = "month";
      statsRequest.starting = moment().subtract(11, "months").format();

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          this.stats.overview = Object.values(
            res.data
              .map((e) => new AssetEventsStats(e))
              .reduce((acc, e) => {
                if (!acc[e.period])
                  acc[e.period] = {
                    month: moment(e.period, "YYYY-MM").format("MMM YYYY"),
                  };

                acc[e.period][e.severity] = e.count;

                return acc;
              }, {})
          );

          this.loadingAlarms.overview = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAlarms.overview = false;
        }
      );

      // Fetch week criticals
      statsRequest = new AssetEventsStatsRequestBody();

      statsRequest.groupBySeverity = true;
      statsRequest.groupInterval = "day";
      statsRequest.severities = [Event.severity.HIGH];
      statsRequest.starting = moment().startOf("day").format();

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          this.stats.high = res.data
            .map((e) => new AssetEventsStats(e))
            .reduce((acc, e) => acc + e.count, 0);

          this.loadingAlarms.high = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAlarms.high = false;
        }
      );

      // Fetch week warnings
      statsRequest = new AssetEventsStatsRequestBody();

      statsRequest.groupBySeverity = true;
      statsRequest.groupInterval = "day";
      statsRequest.severities = [Event.severity.MEDIUM];
      statsRequest.starting = moment().startOf("day").format();

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          this.stats.medium = res.data
            .map((e) => new AssetEventsStats(e))
            .reduce((acc, e) => acc + e.count, 0);

          this.loadingAlarms.medium = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAlarms.medium = false;
        }
      );

      // Fetch week medium severity
      statsRequest = new AssetEventsStatsRequestBody();

      statsRequest.groupBySeverity = true;
      statsRequest.groupInterval = "day";
      statsRequest.severities = [Event.severity.LOW];
      statsRequest.starting = moment().startOf("day").format();

      ApiRequests.getSavedEventsStats(
        statsRequest,
        (res) => {
          this.stats.low = res.data
            .map((e) => new AssetEventsStats(e))
            .reduce((acc, e) => acc + e.count, 0);

          this.loadingAlarms.low = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAlarms.low = false;
        }
      );
    },
    fetchEntityCodes() {
      this.loadingAssets.entityCodes = true;

      ApiRequests.getAssetsCountPerEc(
        (res) => {
          const tmpEcs = Object.keys(res.data)
            .map((ec) => {
              return ec != "-"
                ? {
                    name: ec,
                    value: res.data[ec],
                  }
                : undefined;
            })
            .filter((ec) => ec !== undefined);

          // Normalize length to 24 entity codes
          while (tmpEcs.length < 24) tmpEcs.push({});

          this.stats.entityCodes = tmpEcs.slice(0, 24);
          this.loadingAssets.entityCodes = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loadingAssets.entityCodes = false;
        }
      );
    },
    resetFilters() {
      this.searchTerm = undefined;
      this.carouselSelected = undefined;
      this.carouselSelectedGroup = undefined;
      this.filters = {
        status: "all",
        alarms: "all",
        brand: undefined,
        model: undefined,
        group: undefined,
        customer: undefined,
      };

      this.$refs.assetMap.setBoundingBox(this.filteredCoords);
    },
    openGroup(groupId) {
      this.$router.push({ path: `/groups-details/${groupId}` });
    },
    handleClick(idOrAsset, filter = false) {
      if (!filter) {
        this.$router.push({ path: `/asset-details/${idOrAsset}` });
        return;
      }

      if (this.carouselSelected == idOrAsset.serial) {
        this.carouselSelected = undefined;
        this.carouselSelectedGroup = undefined;

        this.$refs.assetMap.setBoundingBox(this.filteredCoords);

        return;
      }

      // This asset has a group, draw it on the map instead (if it has coordinates)
      // eslint-disable-next-line prettier/prettier
      const assetGroup = this.groupsList.find((g) => g.id === idOrAsset.groupId);
      const groupLon = Number(assetGroup?.lon || undefined);
      const groupLat = Number(assetGroup?.lat || undefined);

      if (!isNaN(groupLat) && !isNaN(groupLon)) {
        const groupCoord = {
          coords: [groupLon, groupLat],
          name: assetGroup.name,
          id: assetGroup.id,
          isGroup: true,
        };

        this.$refs.assetMap.setBoundingBox([groupCoord]);
        this.carouselSelected = idOrAsset.serial;
        this.carouselSelectedGroup = assetGroup.id;

        return;
      }

      const measures = this.$store.getters.measuresById(idOrAsset.serial);
      const lat =
        measures[this.gpsTopics.lat]?.value?.content ||
        measures[this.gpsTopics.altLat]?.value?.content;
      const lon =
        measures[this.gpsTopics.lon]?.value?.content ||
        measures[this.gpsTopics.altLon]?.value?.content;
      const ts =
        measures[this.gpsTopics.lat]?.value?.ts ||
        measures[this.gpsTopics.altLat]?.value?.ts;
      const coordsObj =
        !!lat && !!lon
          ? [
              {
                coords: [lon, lat],
                ts,
                name: idOrAsset.plate || idOrAsset.serial,
                id: idOrAsset.id,
                serial: idOrAsset.serial,
              },
            ]
          : undefined;

      this.$refs.assetMap.setBoundingBox(coordsObj);
      this.carouselSelected = idOrAsset.serial;
      this.carouselSelectedGroup = undefined;
    },
    scrollTo(domReference) {
      this.$refs.dashboardBlocks.$el.scrollTop = domReference.$el.offsetTop;
    },
  },
};
</script>
